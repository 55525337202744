<template>
  <section class="staffManage">
    <h2 class="title-page">员工管理</h2>
    <div class="content-staff">
      <div class="staff-flex staff-info">
        <div class="staff-flex">
          <el-input
            placeholder="请输入姓名"
            suffix-icon="el-icon-search"
            v-model="name"
            class="name-input"
          ></el-input>
          <div class="staff-flex">
            <label class="select-label">岗位</label>
            <el-select v-model="stations" placeholder="请选择">
              <el-option
                v-for="item in stationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="staff-flex">
            <label class="select-label">服务区域</label>
            <el-select v-model="services" placeholder="请选择">
              <el-option
                v-for="item in stationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div>
          <el-button type="primary" @click="showEdit(1)">设置岗位</el-button>
          <el-button type="primary" @click="showEdit(2)">设置所属门店</el-button>
          <el-button type="primary" @click="showEdit(3)">设置服务区域</el-button>
          <el-button type="primary" >同步企微通讯录</el-button>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%" @selection-change="selectionChange"  :header-cell-style="{'background-color':'rgba(175, 192, 207, 0.2)'}">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="姓名" width="180"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="gw" label="岗位"></el-table-column>
        <el-table-column prop="md" label="所属门店"></el-table-column>
        <el-table-column prop="qy" label="服务区域"></el-table-column>
        <el-table-column prop="ht" label="后台登录">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.ht" :active-text="scope.row.ht?'权限管理':''"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <el-button type="text" @click="redirectPas">重置密码</el-button>
        </el-table-column>
      </el-table>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog :title="titleInfo" :visible.sync="editDialog" width="680px" :before-close="beforeClose">
      <p>
        <span>员工：</span>
        <font color="#0081FF">豆荚、张三、里斯</font>
      </p>
      <div class="station-group">
        <el-checkbox :indeterminate="false" @change="checkAllChange">全选</el-checkbox>
        <!-- 岗位管理 -->
        <el-checkbox-group v-model="checkedStation" @change="checkedStationChange">
          <el-checkbox label="1">IT巡检元</el-checkbox>
          <el-checkbox label="2">服务员</el-checkbox>
        </el-checkbox-group>
      </div>
      <div slot="footer">
        <el-button @click="beforeClose" class="close-btn">取 消</el-button>
        <el-button type="primary" @click="setEdit">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
  export default {
    name: "staffManage",
    data() {
      return {
        name: "",
        stations: "",
        services: "",
        stationList: [
          {
            value: 1,
            label: "选择1"
          }
        ],
        tableData: [
          {
            name: "张三",
            phone: "1231231312",
            gw: "服务员、IT巡检员",
            md: "音乐派天府三街店",
            qy: "一楼、二楼",
            ht: false
          }
        ],
        editDialog: false, //展示编辑弹窗
        checkedStation: [],
        editInfo: 0, //1岗位管理，2所属门店，3服务区域，4权限管理
      };
    },
    computed: {
      titleInfo: function(){
        let val = '';
        switch(this.editInfo) {
          case 1:
            val = '岗位管理';
            break;
          case 2:
            val = '所属门店';
            break;
          case 3:
            val = '服务区域';
            break;
          case 4:
            val = '权限管理';
            break;
          default:
            val = '空'
        }
        return val;
      }
    },
    methods: {
      beforeClose: function(){
        this.editInfo = 0;
        this.editDialog = false;
      },
      showEdit: function(val){
        this.editInfo = val;
        this.editDialog = true;
      },
      //全选事件
      selectionChange: function(val) {
        console.log(val);
      },
      //确认按钮
      setEdit: function(){
        this.editDialog = false;
      },
      checkAllChange: function(val){
        console.log(val)
      },
      checkedStationChange: function(val){
        console.log(val)
      },
      //重置密码
      redirectPas: function(){
        this.$alert('是否重置密码为123456', '重置密码', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: `重置成功${action}`
            });
          }
        });
      }
    }
  };
</script>

<style scoped>
  .staffManage {
    min-height: 100%;
    background-color: #f7fafb;
  }
  .title-page {
    font-size: 30px;
    color: #3e566e;
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .staff-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .select-label {
    margin-left: 40px;
    margin-right: 20px;
    white-space: nowrap;
  }
  .el-select {
    min-width: 150px;
  }
  .staff-info {
    padding: 40px;
  }
  .content-staff {
    background-color: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(62, 86, 110, 0.06);
    border-radius: 6px;
  }
  .el-table {
    padding: 0 40px;
  }
  .name-input {
    width: 190px;
  }
  .staffManage /deep/ .el-dialog__footer{
    text-align: center;
  }
  .station-group{
    margin-top: 20px;
    padding: 20px;
    min-height: 200px;
    border: 1px solid rgba(62, 86, 110, 0.2);
    border-radius: 6px;
  }
  .station-group > .el-checkbox-group{
    margin-top: 20px;
  }
  .staffManage /deep/ .el-dialog__title{
    font-size: 20px;
    color: #3E566E;
    font-weight: 500;
  }
  .close-btn{
    background-color: rgba(62, 86, 110, 0.1);
    border-color: rgba(62, 86, 110, 0.1);
  }
</style>